<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入设备名称"></el-input>
            </el-form-item>
            <!-- <el-form-item label="选择园区">
              <el-select v-model="farmID" @change="farmChange">
                <el-option
                  v-for="item in farmList"
                  :key="item.FarmId"
                  :label="item.FarmName"
                  :value="item.FarmId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择大棚">
              <el-select v-model="greenHouseID" @change="houseChange">
                <el-option
                  v-for="item in greenHouseList"
                  :key="item.GreenHouseId"
                  :label="item.GreenHouseName"
                  :value="item.GreenHouseId"
                ></el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 1vh;"
    >
      <!-- <el-table-column
        prop="DevId"
        label="设备ID"
        show-overflow-tooltip
        align="center"
      ></el-table-column> -->
      <el-table-column
        prop="cusdeviceName"
        label="设备名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cusdeviceNo"
        label="设备编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cusdeviceConfigStatus"
        label="设备状态"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.cusdeviceConfigStatus == 1">已配置</span>
          <span v-if="scope.row.cusdeviceConfigStatus == 0">未配置</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="projectName"
        label="组织名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            @click="detail(scope.row.cusdeviceNo)"
            type="success"
            size="small"
            >控制</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!-- 控制 -->
    <el-dialog
      title=""
      :visible.sync="messageBox"
      width="64vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"
        ></span>
      </div>
      <div class="addAlaForm" v-for="(item, index) in list" :key="index">
        <h1 class="titles">{{ item.name }}</h1>
        <div>
          <div class="updataItem">
            <div class="itemOne">操作</div>
            <div class="itemTwo">
              <div>
                <el-button
                  type="danger"
                  size="mini"
                  @click="cease2(item)"
                  >停止</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="expand2(item)"
                  >启动</el-button
                >
              </div>
            </div>
          </div>
          <!-- <div class="updataItem" style="margin-bottom:0.5vh">
            <div class="itemOne">模拟数据</div>
            <div class="itemTwo">
              <el-dropdown>
                <el-button type="success">发送</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(i, index) in item.ValueMap"
                    :key="index"
                    :value="i.Value"
                    @command="saveForm(item, i.Value)"
                    >{{ i.DisplayValue }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
    //   loading: true,
      tableData: [],
      formTitle: "",
      messageBox: false,
      addForm: {},
      rules: {
        name: [
          {
            required: true,
            message: "设备名称不可为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      farmList: [],
      farmID: "",
      greenHouseList: [],
      greenHouseID: "",
      list: [],
      devCode: "",
      textValue: "",
      page: 1,
      size: 10,
      total: 0,
      searchName: '',
      switch1: false,
      url: '',
    };
  },
  mounted() {
    // this.getFarmList();
    this.getList();
    this.getCommandUrl();
  },
  methods: {
    getList(){
      this.$post("/shuiFeiDevice/getCusdevices", {
        pageNo: this.page,
        pageSize: this.size,
        cusdeviceName: this.searchName,
        projectId: '',
        allCusdeviceStatus: '',
        templateId: ''
      }).then((res) => {
        if(res.data.state == "success"){
          let datas = res.data.data.data.cusdeviceResponseDTO;
          this.tableData = [];
          for(let a = 0; a < datas.length; a++){
            if(datas[a].cusdeviceNo == '0000042578000232'){
              this.tableData.push(datas[a]);
            }
          }
          // this.tableData = res.data.data.data.cusdeviceResponseDTO;
          this.total = res.data.data.data.total;
          //console.log(this.tableData);
        }
      })
    },
    // 请求园区列表
    async getFarmList() {
      const { data } = await this.$get("/shuiFeiDevice/getFarmList");
      if (data.state == "success") {
        this.farmList = data.data.Data;
        this.farmID = data.data.Data[0].FarmId;
        //console.log("园区列表", this.farmList);
        this.getHouseList(this.farmID);
      }
    },
    farmChange(item) {
      this.getHouseList(item);
    },
    async getHouseList(farmId) {
      const { data } = await this.$get("/shuiFeiDevice/getGreenhouseList", {
        farmId,
      });
      if (data.state == "success") {
        this.greenHouseList = data.data.Data;
        this.greenHouseID = data.data.Data[0].GreenHouseId;
        //console.log("大棚列表", this.greenHouseList);
        this.getDevList(this.greenHouseID);
      }
    },
    houseChange(item) {
      this.getDevList(item);
    },
    async getDevList(greenHouseId) {
      const { data } = await this.$get("/shuiFeiDevice/getDevList", {
        greenHouseId,
      });
      if (data.state == "success") {
        this.tableData = data.data.Data;
        // this.loading = false;
        //console.log("设备列表", this.tableData);
      }
    },
    // 控制
    async detail(item) {
      this.list = [];
      this.devCode = item;
      this.formTitle = "控制";
      this.messageBox = true;
      // const { data } = await this.$get("/shuiFeiDevice/getDevValue", {
      //   devCode: item,
      // });
      // if (data.state == "success") {
      //   let datas = data.data.Data;
      //   //console.log("详情", datas);
      //   this.list = datas;
      // }
      this.$post("/shuiFeiDevice/getDataPointInfoForCusdeviceNo", {
        cusdeviceNo: item,
        slaveId: '',
        pageNo: this.page,
        pageSize: 32
      }).then((res) => {
        if(res.data.state == "success"){
          let datas = res.data.data.data.cusdeviceDataPointList;
          let dataAll = [];
          dataAll.push(datas[0]);
          for(let i = 5; i < datas.length; i++){
              dataAll.push(datas[i])
          }
          this.list = dataAll;
        }
      });
      // this.$post("/shuiFeiDevice/getCusdeviceInfo", {
      //   cusdeviceNo: item
      // }).then((res) => {
      //   if(res.data.state == "success"){
      //      this.list = res.data.data.data;
      //   }
      // })
    },
    // 命令下发地址
    getCommandUrl(){
      this.$post("/shuiFeiDevice/getCommandAddress", {

      }).then((res) => {
        if(res.data.state == "success"){
          this.url = res.data.data.data.commandServerAddr;
        }
      })
    },
    cease2(val){
      let setDataPoint = {
        'dataPointRelId': val.dataPointRelId,
        'value': '00'
      };
      this.$postJSON("/shuiFeiDevice/dataPoint?url=" + this.url + "&cusdeviceNo=" + val.cusdeviceNo, 
        [setDataPoint]
        ).then((res) => {
        if(res.data.state == "success"){
          this.$message.success("关闭成功");
        }
      })
    },
    expand2(val){
      let setDataPoint = {
        'dataPointRelId': val.dataPointRelId,
        'value': '255'
      };
      this.$postJSON("/shuiFeiDevice/dataPoint?url=" + this.url + "&cusdeviceNo=" + val.cusdeviceNo, 
        [setDataPoint]
        ).then((res) => {
        if(res.data.state == "success"){
          this.$message.success("开启成功");
        }
      })
    },
    // 停止
    async cease(item) {
      this.textValue = item.FieldName;
      const { data } = await this.$get("/shuiFeiDevice/setProperty", {
        devCode: this.devCode,
        fieldName: item.FieldName,
        value: item.ValueMap[0].Value,
        valueType: item.ValueType,
      });
      if (data.state == "success") {
        this.$message.success("操作成功");
        this.detailTwo(this.devCode, this.textValue);
      }
    },
    // 展开
    async expand(item) {
      this.textValue = item.FieldName;
      const { data } = await this.$get("/shuiFeiDevice/setProperty", {
        devCode: this.devCode,
        fieldName: item.FieldName,
        value: item.ValueMap[1].Value,
        valueType: item.ValueType,
      });
      if (data.state == "success") {
        this.$message.success("操作成功");
        this.detailTwo(this.devCode, this.textValue);
      }
    },
    // 收拢
    async Collapse(item) {
      this.textValue = item.FieldName;
      const { data } = await this.$get("/shuiFeiDevice/setProperty", {
        devCode: this.devCode,
        fieldName: item.FieldName,
        value: item.ValueMap[1].Value,
        valueType: item.ValueType,
      });
      if (data.state == "success") {
        this.$message.success("操作成功");
        this.detailTwo(this.devCode, this.textValue);
      }
    },
    //确认提交
    async saveForm(item, text) {
      this.textValue = item.FieldName;
      const { data } = await this.$get("/shuiFeiDevice/setProperty", {
        devCode: this.devCode,
        fieldName: item.FieldName,
        value: text,
        valueType: item.ValueType,
      });
      if (data.state == "success") {
        this.$message.success("操作成功");
        this.detailTwo(this.devCode, this.textValue);
      }
    },
    // 请求
    async detailTwo(item, fieldName) {
      const { data } = await this.$get("/shuiFeiDevice/getDevValue", {
        devCode: item,
        fieldName: fieldName,
      });
      if (data.state == "success") {
        // this.detail(item);
      }
    },
    search() {
      this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  height: 55vh;
  overflow: scroll;
}
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}
#content .drawerBox /deep/ .el-table {
  max-height: 75vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm {
  width: 19vw;
  height: 10vh;
  margin: 1vh 0.5vw 0 0.5vw;
  border: 1px solid #000;
  line-height: 3vh;
}
.titles {
  font-size: 2.8vh;
  border-bottom: 1px solid #000;
}
.updataItem {
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;
  font-size: 2vh;
}
.itemOne {
  width: 4vw;
  margin-left: 1vw;
}
.itemTwo {
  margin-right: 1vw;
}
.itemTwoFelx {
  display: flex;
}

.itemTwo /deep/ .el-input__inner {
  height: 3vh;
  width: 4vw;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}
.addAlameBox /deep/ .el-button {
  padding: 4px 9px !important;
}
.itemTwo /deep/ .el-button--success {
  background-color: #85ce61;
  border-color: #85ce61;
  color: #fff;
}
</style>
